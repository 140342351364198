import { useParams } from '@solidjs/router';

import PostList from '~/components/PostList';

export default function Tag() {
  const parameters = useParams();

  return (
    <PostList
      filter={{
        revisionsConnection_SOME: {
          edge: { status: 'CURRENT' },
          node: { tags_SOME: { slug: parameters.tag } },
        },
      }}
    />
  );
}
